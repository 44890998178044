import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/privacy-policy'
import CookiePolicy from './pages/cookie-policy';
import Footer from './components/Footer';
import BackToTopButton from './components/BackToTopButton';
import React, { useState, useEffect } from 'react';

function App() {

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // useEffect(() => {
  //   setIsPopupOpen(true);
  // }, []);

  // const closePopup = () => {
  //   setIsPopupOpen(false);
  // };

  return (
    <>
      {/* {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <img src="img/popup/popup.jpg" alt="Akcija" className="popup-image"/>
            <button onClick={closePopup} className="btn-close" aria-label="Close"></button>
          </div>
        </div>
      )} */}
      <div className='app'>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
        </Routes>
        <Footer />
        <BackToTopButton />
      </div>
    </>
  );
}

export default App;
