import React from 'react';
import Slider from '../components/Slider';
import Info from '../components/Info';
import Usluge from '../components/Usluge';
import Novosti from '../components/Novosti';
import DoctorSlider from '../components/DoctorSlider';
import Onama from '../components/Onama';
import UslugeSlider from '../components/UslugeSlider';
import Cenovnik from '../components/Cenovnik';
import Partneri from '../components/Partneri';
import Contact from '../components/Contact';
import MapComponent from '../components/Map';
import Newsletter from '../components/Newsletter';

function Home() {

    return (
        <>
            <section id='Home' >
                <Slider />
            </section>
            <Info />
            <Usluge />
            <section id='News'>
                <Novosti />
            </section>
            <section id='Team'>
                <DoctorSlider />
            </section>
            <section id='Services'>
                <UslugeSlider />
            </section>
            <section id='About' >
                <Onama />
            </section>
            <section id='Prices'>
                <Cenovnik />
            </section>
            <Partneri />
            <section id="Contact">
                <Contact />
            </section>
            <MapComponent />
            <Newsletter />
        </>
    );
}

export default Home;
